










import { defineComponent } from "@vue/composition-api";

import TaskNavigation from "@/app/components/Task/TaskNavigation.vue";
import TaskView from "@/app/components/Task/TaskView.vue";

export default defineComponent({
  name: "TasksPage",
  components: {
    TaskNavigation,
    TaskView, 
  },
});
