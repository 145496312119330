




import { defineComponent } from "@vue/composition-api";

import BaseNavigation from "@/app/components/Base/BaseNavigation.vue";
import { MAIN_ROUTES } from "@/shared/constants";

export default defineComponent({
  name: "TaskNavigation",
  components: { BaseNavigation },
  setup() {
    const MENU_ITEMS = [
      {
        title: "Задачи",
        to: MAIN_ROUTES.BASE,
      },
      {
        title: "Проекты",
        to: MAIN_ROUTES.PROJECTS,
      },
    ];
    return { MENU_ITEMS };
  },
});
