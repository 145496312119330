
























import { defineComponent, PropType } from "@vue/composition-api";

import { MAIN_ROUTES } from "@/shared/constants";

export interface BaseNavigationItem {
  to: string;
  title: string;
}

export default defineComponent({
  name: "BaseNavigation",
  props: {
    menuItems: { type: Array as PropType<BaseNavigationItem[]> },
    containerClass: {
      type: String,
      default: 'mb-10',
    },
    itemClass: {
      type: String,
      default: null,
    }, 
  },
  setup() {
    return { MAIN_ROUTES };
  },
});
