interface ProjectRoutes {
  BASE: string;
  TASKS: string;
  RESULT: string;
  TEAM: string;
  COMMENTS: string;
  EDIT_PROJECT: string;
}
const prefix = (id: string) => `/projects/${id || ""}`;

export const PROJECT_ROUTES = (id: string): ProjectRoutes => ({
  BASE: `${prefix(id)}`,
  TASKS: `${prefix(id)}/`,
  RESULT: `${prefix(id)}/project-result`,
  TEAM: `${prefix(id)}/project-team`,
  COMMENTS: `${prefix(id)}/project-comments`,
  EDIT_PROJECT: `${prefix(id)}/edit`,
});
